<template>
  <aside>
    <SidePanel @updateSearch="updateSearch" />
  </aside>

  <main>
    <template v-if="filteredComponents.length">
      <RenderComponent
        v-for="(component, index) in filteredComponents"
        :key="`component-${index}-${searchKey}`"
        :is-variant="component.isVariant"
        :component="component"
      />
    </template>
    <template v-else>
      <h1>Welcome to our Component Catalogue</h1>
    </template>
  </main>
</template>

<script>
import RenderComponent from "../components/Render/RenderComponent/RenderComponent";
import SidePanel from "../components/Render/SidePanel/SidePanel";

export default {
  name: "Components",
  components: { SidePanel, RenderComponent },
  data() {
    return {
      componentList: this.globalComponentList,
      searchKey: 0,
    };
  },
  methods: {
    updateSearch() {
      this.searchKey++;
    },
  },
  computed: {
    keyword() {
      if (this.$route.params.keyword) {
        return this.$route.params.keyword;
      }
      return null;
    },
    groupList() {
      const removeDuplicates = (data) => {
        return data.filter((value, index) => data.indexOf(value) === index);
      };

      return removeDuplicates(
        this.componentList.map((component) => component.groupName)
      );
    },
    currentRoute() {
      return this.$route.params.group;
    },
    filteredComponents() {
      return this.componentList.filter(
        (component) =>
          component.groupName === this.currentRoute && !component.isVariant
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
