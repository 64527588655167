<template>
  <ul class="side-navigation-item">
    <li>
      <router-link :to="`/${title}`" active-class="active">
        {{ compTitle }}
      </router-link>
      <template v-if="isActive">
        <ul>
          <li
            v-for="(component, index) in filteredComponentList"
            :key="`component-${index}`"
          >
            <router-link
              :to="`/${title}/${component.name}`"
              active-class="active"
            >
              {{ component.name }}
            </router-link>
          </li>
        </ul>
      </template>
    </li>
  </ul>
</template>

<script>
import { startCase, camelCase } from "lodash";
export default {
  name: "SideNavigationItem",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      componentList: this.globalComponentList,
    };
  },
  computed: {
    isActive() {
      return this.$route.params.group === this.title;
    },
    currentRoute() {
      return this.$route.path.replace("/", "");
    },
    compTitle() {
      return startCase(camelCase(this.title));
    },
    filteredComponentList() {
      return this.componentList.filter(
        (component) =>
          component.groupName === this.title && !component.isVariant
      );
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
}
li {
  margin: 2px 0;
  transition: ease-in-out all 0.2s;
  padding: 6px;
  cursor: pointer;
  font-size: 14px;
  list-style: none;

  a {
    text-decoration: none;
    display: block;
    color: #3c3c3cb3;
    font-weight: bold;
    transition: ease-in-out all 0.2s;

    &.active {
      color: #42b983;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
    a {
      color: #42b983;
    }
  }

  ul {
    li {
      a {
        font-weight: normal;
      }
    }
  }

  &:active {
    color: #000;
    font-weight: bold;
  }
}
</style>
