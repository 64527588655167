<template>
  <div class="side-panel">
    <h4>Component Categories</h4>
    <ul class="side-navigation">
      <SideNavigationItem
        v-for="(group, index) in groupList"
        :key="`group-${index}`"
        :title="group"
      />
    </ul>
    <div class="search-panel">
      <input type="search" v-model="keyword" placeholder="Search components" />

      <ul v-if="foundComponents" class="found-items">
        <router-link
          v-for="(item, index) in foundComponents"
          :key="`item-${index}`"
          :to="`/${item.groupName}/${item.name}`"
          @click="updateSearch"
        >
          <code>
            <strong>{{ item.name }}</strong>
          </code>
          <br />
          in {{ item.groupName }}
        </router-link>
      </ul>
    </div>
    <div class="theme-panel">
      <span class="caps">Theme</span>
      <div class="flex">
        <div>
          <label>{{ vendorTitle }}</label>
          <input type="radio" v-model="theme" value="{{ vendorTitle }}" />
        </div>
        <div>
          <label>Default</label>
          <input type="radio" v-model="theme" value="default" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavigationItem from "@/components/Render/SidePanel/SideNavigation/SideNavigationItem";

export default {
  name: "SidePanel",
  components: {
    SideNavigationItem,
  },
  data() {
    return {
      componentList: this.globalComponentList,
      keyword: "",
      theme: "default",
    };
  },
  mounted() {
    if (this.$route.params.keyword) {
      this.keyword = this.$route.params.keyword;
    }
  },
  methods: {
    updateSearch() {
      this.$emit("updateSearch");
    },
  },
  computed: {
    foundComponents() {
      if (this.keyword.length > 3) {
        return this.componentList.filter((component) =>
          component.name
            .toLowerCase()
            .includes(this.keyword.toLowerCase().trim())
        );
      }
      return "";
    },
    groupList() {
      const removeDuplicates = (data) => {
        return data.filter((value, index) => data.indexOf(value) === index);
      };

      return removeDuplicates(
        this.componentList.map((component) => component.groupName)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  padding: 6px;
  margin: 0;
  color: var(--color-p900);
  font-size: 14px;
}
.search-panel {
  border-top: 1px solid #f6f6f6;
  padding: 24px 0;
  margin-top: 12px;
  input {
    font-size: 13px;
    border-radius: 20px;
    width: 80%;
  }

  ul.found-items {
    background: lightyellow;
    padding: 10px;
    list-style: none;
    font-size: 13px;

    a {
      text-decoration: none;
      display: block;
      color: #9b9b9b;
      padding: 4px;
      cursor: pointer;
      transition: ease-in-out all 0.2s;

      &:hover {
        color: #000;
      }
    }
  }
}
.theme-panel {
  border-top: 1px solid #f6f6f6;
  padding: 24px 0;
  margin-top: 12px;

  .caps {
    text-transform: uppercase;
    font-size: 13px;
    display: block;
    margin-bottom: 4px;
    color: #9b9b9b;
  }
  .flex {
    display: flex;
    gap: 24px;
    margin-top: 4px;

    div {
      display: flex;

      label {
        font-size: 13px;
      }
    }
  }
}
</style>
