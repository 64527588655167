<template>
  <div
    :ref="`component-${componentName}`"
    class="render-component"
    :class="[
      { highlight: searchKeyword === componentName },
      { variant: component.isVariant },
    ]"
  >
    <div class="title-container">
      <h4 class="main-title">{{ title }}</h4>
    </div>
    <div class="component-wrapper">
      <div v-show="isVariant || variants.length === 0">
        <div class="component-inner">
          <component
            :is="componentName"
            :ref="componentName"
            v-bind="componentLiveProps"
          />
        </div>
      </div>

      <div class="description" v-if="component.description">
        <h4>Description</h4>
        <p>{{ component.description }}</p>
      </div>

      <template v-if="componentProps">
        <h4 class="toggle-props" @click="toggleProps">
          <template v-if="showProps"> Hide Props </template>
          <template v-else> Show Props </template>
        </h4>
        <template v-if="showProps">
          <ul class="props">
            <li
              v-for="(defaultData, prop) in componentProps"
              :key="`prop-${prop}`"
            >
              <div class="prop-row">
                <div class="prop">
                  {{ prop }}
                </div>

                <template v-if="defaultData">
                  <div class="default-data" v-if="defaultData">
                    <pre><strong>Default:</strong> {{ defaultData }}</pre>
                  </div>
                </template>
                <template v-else>
                  <div class="default-data">N/A</div>
                </template>
              </div>
            </li>
          </ul>
        </template>
      </template>

      <div class="component-tag-and-path">
        <div v-if="figmaUrl" class="figma-url">
          <code><strong>Figma:</strong></code>

          <a :href="figmaUrl" target="_blank">
            <img src="@/assets/figma_logo.svg" alt="Figma" /> Click to see on
            Figma
          </a>
        </div>

        <div class="component-tag">
          <code><strong>Tag:</strong> &lt;{{ componentName }} /&gt;</code>
        </div>

        <div class="component-path">
          <code
            ><strong>Path:</strong> components/{{ groupName }}/{{
              componentName
            }}.vue</code
          >
        </div>
      </div>

      <div v-if="!isVariant && variants.length > 0" class="variants">
        <h3>Variants</h3>
        <div class="variants__inner">
          <RenderComponent
            v-for="(variant, index) in variants"
            :key="`variant-${index}`"
            :component="variant"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startCase, camelCase } from "lodash";

export default {
  name: "RenderComponent",
  props: {
    component: {
      type: Object,
      required: true,
    },
    isVariant: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      componentProps: null,
      componentList: this.globalComponentList,
      showProps: false,
    };
  },
  watch: {
    componentName: {
      handler() {
        this.$nextTick(() => {
          this.updateComponentProps();
        });
      },
    },
  },
  methods: {
    toggleProps() {
      this.showProps = !this.showProps;
    },
    updateComponentProps() {
      const props = this.$refs[this.componentName].$props;
      if (Object.values(props).length) {
        this.componentProps = props;
      }
    },
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      setTimeout(() => {
        window.scrollTo(0, top);
      }, 300);
    },
  },
  computed: {
    figmaUrl() {
      return this.component.figmaUrl;
    },
    componentLiveProps() {
      if (this.component.props) {
        return this.component.props;
      }
      return null;
    },
    componentName() {
      return this.component.name;
    },
    variants() {
      if (!this.isVariant) {
        return this.componentList.filter(
          (component) =>
            component.parent === this.componentName.replace("Base", "") &&
            component.isVariant
        );
      }
      return [];
    },
    groupName() {
      return this.$route.params.group;
    },
    searchKeyword() {
      if (this.$route.params.keyword) {
        return this.$route.params.keyword;
      }
      return null;
    },
    title() {
      return startCase(camelCase(this.componentName));
    },
  },

  mounted() {
    if (this.searchKeyword === this.componentName) {
      this.scrollMeTo(`component-${this.searchKeyword}`);
    }
    this.updateComponentProps();
  },
};
</script>

<style lang="scss" scoped>
.render-component {
  padding: 48px 0 0 0;

  &.variant {
    padding: 0;
  }
  &.highlight {
    h3 {
      color: orange;
    }
    .component-wrapper {
      background: lightyellow;
    }
  }

  h3 {
    margin: 0 0 6px 0;
    font-weight: 500;
  }

  h4.main-title {
    background: var(--color-secondary);
    color: white;
    padding: 10px;
    margin: 0;
  }
  .component-wrapper {
    background: #fafafa;
    padding: 24px;
    margin-bottom: 48px;

    .component-inner {
      background: white;
      padding: 24px;
    }

    .description {
      background: #f4f5f6;
      border-radius: 6px;
      padding: 12px;
      margin-top: 12px;

      h4 {
        font-size: 12px;
        margin: 0;
        padding: 0;
        border-bottom: 0;
      }
    }

    h4 {
      line-height: 1.6;
      font-size: 15px;
      margin-bottom: 4px;

      &.toggle-props {
        font-size: 13px;
        cursor: pointer;
        display: inline-block;
        border: 1px solid var(--color-secondary);
        background: white;
        padding: 4px 12px;
        border-radius: 6px;
        transition: var(--transition-default);

        &:hover {
          background: var(--color-secondary);
          color: white;
        }
      }
    }

    p {
      font-size: 13px;
    }

    .props {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 14px;

      .prop-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        gap: 24px;
        background: #fff;

        .prop {
          background: #f4f5f6;
          font-weight: bold;
          padding: 4px 12px;
          flex: 1;
          min-width: 140px;
          max-width: 140px;
          font-size: 13px;
          align-items: center;
          display: flex;
        }
        .default-data {
          flex: 2;
          font-size: 12px;
        }
      }
    }
  }

  .variants {
    padding: 24px;
    background: white;
    margin-top: 24px;

    h3 {
      margin: 0 10px 0 -24px;
      background: #f4f5f6;
      padding: 4px 12px;
      text-align: center;
      width: 100px;
      font-size: 17px;
    }

    .variants__inner {
      padding: 10px;
    }
  }

  .component-tag-and-path {
    .figma-url,
    .component-tag,
    .component-path {
      background: #fff;
      border-radius: 4px;
      padding: 4px 12px;
      font-size: 12px;
      margin-top: 12px;
      opacity: 0.7;
      display: flex;
      align-items: center;

      img {
        height: 16px;
        margin: 0 10px;
      }

      code {
        display: block;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
